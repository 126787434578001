<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <!-- <ComponentUpdateVisionKey/>
        <ComponentDeveloperIndustryMatched/>
        <ComponentDeveloperIndustryUnmatched/> -->
        <PageDeveloperUploadExcel/>
        <ComponentDeveloperIndustryNull/>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
// import ComponentDeveloperIndustryMatched from '../../components/developer/clean/ComponentDeveloperIndustryMatched.vue';
// import ComponentDeveloperIndustryUnmatched from '../../components/developer/clean/ComponentDeveloperIndustryUnmatched.vue';
import ComponentDeveloperIndustryNull from '../../components/developer/clean/ComponentDeveloperIndustryNull.vue'
import PageDeveloperUploadExcel from '../../views/developer/PageDeveloperUploadExcel.vue';
// import ComponentUpdateVisionKey from '../../components/developer/clean/ComponentUpdateVisionKey.vue';
export default {
    components:{
    // ComponentDeveloperIndustryMatched,
    // ComponentDeveloperIndustryUnmatched,
    ComponentDeveloperIndustryNull,
    PageDeveloperUploadExcel,
    // ComponentUpdateVisionKey
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
          
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>