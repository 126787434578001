<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload :api="this.api" @close="fetch()"/>
        <v-data-table
            v-if="!api.isLoading && dataCompanyMatched!=null"
            :items="dataCompanyMatched"
            :headers="dataHeader"
            :search="searchCompanyMatched"
            class="elevation-1 mt-4" >
            <template v-slot:item.name="{item}">
                <span v-if="item.name!=null">
                    {{ item.name }}
                </span> 
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.vision_key="{item}">
                <span v-if="item.vision_key!=null">
                    {{ item.vision_key }}
                </span> 
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.excel_key="{item}">
                <span v-if="item.excel_key!=null">
                    {{ item.excel_key }}
                </span> 
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.industry="{item}">
                <span v-if="item.industry!=null">
                    {{ item.industry}}
                </span> 
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.business_nature="{item}">
                <span v-if="item.business_nature!=null">
                    {{ item.business_nature}}
                </span> 
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" class="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3" >
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Company Empty Industry
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="fetch()"  class="mx-3">
                                    Refresh
                                </v-btn> 
                                <v-btn color="primary" @click="updateCompanyIndustry()">
                                    Fix
                                </v-btn>
                                <JsonCSV :data="dataCompanyMatched" class="mx-2">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>

                            </v-row>    
                            <v-row>
                                <v-text-field
                                    v-model="searchCompanyMatched"
                                    dense 
                                    outlined 
                                    label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                   
                </v-toolbar>
            </template>
            <template
                v-slot:item.Index="{item}">
                {{dataCompanyMatched.indexOf(item)+1 }}
            </template>
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataCompanyMatched:null,
        searchCompanyMatched:"",
        dataHeader:[
            {
                text:'#',
                value:'Index',
            },
            {
                text:'Company',
                value:'name',
            },
            {
                text:'Vision Key',
                value:'vision_key',
            },
            {
                text:'Excel key',
                value:'excel_key',
            },
            {
                text:'industry(database)',
                value:'industry',
            },
            {
                text:'industry(Excel)',
                value:'business_nature'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isSuccesful = false;
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getCompaniesWithNullIndustry") {
                
                this.dataCompanyMatched = resp.data.company;
            }
            if(resp.class==="fixCompaniesIndustryUsingExcel") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update the excel";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchDeveloperIndustryMatchedApi = this.fetchDeveloperIndustryMatched();
            this.$api.fetch(fetchDeveloperIndustryMatchedApi);
        },
        fetchDeveloperIndustryMatched(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/industry/empty";
            return tempApi;
        },  
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        updateCompanyIndustry()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/fix/industry";
            this.$api.fetch(tempApi);
        }
    }
}
</script>